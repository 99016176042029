<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #00254d"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <img
            src="media/logos/logo_lavazza_white.svg"
            class="max-h-60px logo"
            alt=""
          />
        </div>
        <div
          class="
            aside-img
            d-flex
            flex-row-fluid
            bgi-no-repeat bgi-position-y-bottom bgi-position-x-center
          "
        ></div>
        <!-- :style="{ backgroundImage: `url(${backgroundImage})` }" -->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Benvenuto!
                </h3>
              </div>
              <div class="form-group">
                <label class="font-size-h6 text-dark">E-mail</label>
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="text"
                    name="email"
                    ref="email"
                    v-model="form.email"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h6 text-dark pt-5">Password</label>
                  <span class="forgot-pwd">
                    Hai dimenticato la password?
                    <a
                      class="
                        text-primary
                        font-size-h6 font-weight-bolder
                        text-hover-primary
                        pt-5
                      "
                      id="kt_login_forgot"
                      @click="showForm('forgot')"
                      >Recuperala</a
                    >
                  </span>
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="
                      form-control form-control-solid
                      h-auto
                      py-7
                      px-6
                      rounded-lg
                    "
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Accedi
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  Hai dimenticato la password?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Inserisci la tua e-mail per resettare la password
                </p>
              </div>
              <div class="form-group">
                <input
                  class="
                    form-control form-control-solid
                    h-auto
                    py-7
                    px-6
                    rounded-lg
                    font-size-h6
                  "
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  autocomplete="off"
                  v-model="email_for_reset"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  id="kt_login_forgot_submit"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                    mr-4
                  "
                >
                  Invia
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="showForm('signin')"
                >
                  Annulla
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      email_for_reset: "",
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/travaj_login1.jpeg";
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    const forgot_form = KTUtil.getById("kt_login_forgot_form");

    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "E-mail obbligatoria",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password obbligatoria",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv2 = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "E-mail obbligatoria",
            },
            emailAddress: {
              message: "L'e-mail inserita non è valida",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      if (submitButton) {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      }

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(LOGIN, { email: email, password: password })
          .then((user) => {
            if (user == null) {
              return this.$bvModal.msgBoxOk(this.$t("login.invalid-access"), {
                size: "sm",
                buttonSize: "sm",
                okTitle: "OK",
                centered: true,
              });
            }

            // ROUTE

            this.$router.push({ path: "/" });
          })
          .catch((response) => {
            response;
            // let message = Utils.parseErrorMessage(response);
            // this.$bvModal.msgBoxOk(message, {
            //   size: "sm",
            //   buttonSize: "sm",
            //   okTitle: "OK",
            //   centered: true,
            // });
          });
        if (submitButton) {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }
      }, 2000);
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: "Please, provide correct data!",
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false,
      });
    });

    this.fv2.on("core.form.valid", () => {
      var email = this.email_for_reset;

      // clear existing errors
      this.$store.dispatch(LOGOUT);

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_forgot_submit"];
      if (submitButton) {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      }

      // dummy delay
      setTimeout(() => {
        // send login request
        this.$store
          .dispatch(RESET_PASSWORD, email)
          .then((user) => {
            user;
            // if (user == null) {
            //   return this.$bvModal.msgBoxOk(this.$t("login.invalid-access"), {
            //     size: "sm",
            //     buttonSize: "sm",
            //     okTitle: "OK",
            //     centered: true,
            //   });
            // }

            // ROUTE
            //this.$router.push({ name: "utenti" });
          })
          .catch((response) => {
            response;
            // let message = Utils.parseErrorMessage(response);
            // this.$bvModal.msgBoxOk(message, {
            //   size: "sm",
            //   buttonSize: "sm",
            //   okTitle: "OK",
            //   centered: true,
            // });
          });
        if (submitButton) {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        }
      }, 2000);
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 1281px) {
  .logo {
    margin-top: 11%;
  }
  .forgot-pwd {
    color: #bab7b4;
    font-weight: 500 !important;
    font-size: 1.175rem !important;
    margin-top: 17px;
  }
  .forgot-pwd a {
    color: #282828 !important;
    border-bottom: 2px solid #d2b487;
    font-weight: 500 !important;
    cursor: pointer;
  }
}
</style>
